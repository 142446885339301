// 解决方案 子页面
<template>
  <div class="solution-details">
    <Bigimg
      title="应用平台"
      :imgUrl="require('@/assets/images/yypt.jpg')"
    ></Bigimg>
    <div class="solution-details-list wrap-v1">
      <imgLeftorright
        v-for="(item, index) in applicationPlatform"
        :key="item.id"
        @click="goDetail(item)"
        :title="item.title"
        :desc="item.desc"
        :imgUrl="item.bgUrl"
        :direction="index % 2 === 0 ? 'right' : 'left'"
      />
    </div>
  </div>
</template>
<script>
import imgLeftorright from "@/components/img-leftorright";
import solutionList from "./solutionList";
import Bigimg from "@/components/bigimg.vue";

export default {
  components: { imgLeftorright, Bigimg },
  computed: {
    applicationPlatform() {
      return solutionList.filter(item => item.category == "应用平台");
    }
  },
  methods: {
    goDetail(item) {
      this.$router.push(`/solution/details/${item.id}`);
    }
  }
};
</script>
<style scoped lang="scss">
.solution-details {
  .solution-details-list {
    padding: 60px 0 130px;
  }
}
</style>
