<template>
  <div class="img-leftorright">
    <img :src="imgUrl" alt="" v-if="direction === 'left'" />
    <div :class="['detail', direction === 'left' ? 'ml-6' : 'mr-6']">
      <div class="title" @click="$emit('click')">{{ title }}</div>
      <div class="desc">{{ desc }}</div>
    </div>
    <img :src="imgUrl" alt="" v-if="direction === 'right'" />
  </div>
</template>
<script>
export default {
  props: {
    imgUrl: "",
    title: "",
    desc: "",
    direction: { default: "left" }
  }
};
</script>
<style scoped lang="scss">
.ml-6 {
  margin-left: 60px;
}
.mr-6 {
  margin-right: 60px;
}
.img-leftorright {
  display: flex;
  align-items: center;
  margin-top: 60px;
  img {
    flex: 1;
    display: block;
    /* width: 700px; */
    width: 50%;
    /* min-width: 500px; */
  }
  .detail {
    flex: 1;
    text-align: left;
    .title {
      font-size: 45px;
      font-weight: 600;
      color: hsla(0, 0%, 20%, 1);
      line-height: 63px;
      cursor: pointer;
    }
    .desc {
      margin-top: 45px;
      text-align: left;
      font-size: 20px;
      color: hsla(0, 0%, 20%, 1);
      line-height: 40px;
    }
  }
}
</style>
