export default [
  {
    id: 1,
    category: "应用平台",
    categoryUrl: require("@/assets/images/detail-bg.jpg"),
    title: "直播双师课堂解决方案",
    desc:
      "教育信息化2.0加速推进校园建设 从“信息化”走向“互联网化”,连接课前、课中和课后的教学全场景，通过常态化录播、未来教室、智能纸笔、互动平板、扫描拍照等方式采集学生的学习过程数据及日常行为数据，并通过人工智能和大数据进行分析和综合运用，打造个性化的教与学。",
    bgUrl: require("../../assets/images/jjfa1.png"),
    usedList: [
      {
        name: "数字校园",
        imgUrl: require("../../assets/images/jjfa1-1.jpg")
      },
      {
        name: "智慧校园",
        imgUrl: require("../../assets/images/jjfa1-2.png")
      },
      {
        name: "互联网+校园",
        imgUrl: require("../../assets/images/jjfa1-3.jpg")
      }
    ],
    characteristic: require("../../assets/images/fatd1.png"),
    fadetail: {
      desc:
        "以建设/升级改造物联网智慧教室、远程教研室、互动专递直播间、云端教室等为基础，通过校园一体化平台及区域一体化平台，服务于区域内各个校园日常的教学管理体系及区域统一管理，实现区域教育一体化及资源均衡发展，真正推进智能双师课堂在广大中小学校的常态化应用。",
      imgUrl: require("../../assets/images/faxq1.png")
    },
    advantage: [
      { name: "智能管控", imgUrl: require("@/assets/images/5.png") },
      { name: "纸笔互动", imgUrl: require("@/assets/images/6.png") },
      { name: "录播沉淀", imgUrl: require("@/assets/images/7.png") },
      { name: "教室互联", imgUrl: require("@/assets/images/8.png") }
    ],
    partner: ["歌华TV", "腾讯教育"]
  },
  {
    id: 2,
    category: "视频服务",
    categoryUrl: require("@/assets/images/spfw.jpg"),
    title: "空中课堂解决方案",
    desc:
      "一站式在线教育互动课堂的解决方案，围绕在线直播学习的核心场景，提供课中互动、课件管理及内容资源、教学督导、数据统计分析等多种应用场景的解决方案。",
    bgUrl: require("../../assets/images/jjfa2.png"),
    usedList: [
      { name: "课堂直播", imgUrl: require("../../assets/images/jjfa2-1.jpg") },
      { name: "教学督导", imgUrl: require("../../assets/images/jjfa2-2.png") },
      {
        name: "在线教育平台",
        imgUrl: require("../../assets/images/jjfa2-3.jpg")
      }
    ],
    characteristic: require("../../assets/images/fatd2.png"),
    fadetail: {
      desc:
        "以建设/升级改造物联网智慧教室、远程教研室、互动专递直播间、云端教室等为基础，通过校园一体化平台及区域一体化平台，服务于区域内各个校园日常的教学管理体系及区域统一管理，实现区域教育一体化及资源均衡发展，真正推进智能双师课堂在广大中小学校的常态化应用。",
      imgUrl: require("../../assets/images/faxq2.jpg")
    },
    advantage: [
      { name: "互动小班课", imgUrl: require("@/assets/images/5.png") },
      { name: "跨班授课", imgUrl: require("@/assets/images/6.png") },
      { name: "考勤统计", imgUrl: require("@/assets/images/7.png") },
      { name: "多端支持", imgUrl: require("@/assets/images/8.png") }
    ],
    partner: ["歌华TV", "腾讯教育"]
  },
  {
    id: 3,
    category: "应用平台",
    categoryUrl: require("@/assets/images/yypt.jpg"),
    title: "行业应用解决方案",
    desc:
      "顺应时代发展，传统行业转型升级，通过互联网+、物联网等新技术基建进行创新、融合发展，比如设计行业、建设行业、工业制造、供应链或互联产品等等。传统线下为主，转变为线下+线上，更加专注产品生产，利用行业平台服务辅助生产过程的管理、助力产品方案的推广、高效连接用户端与产品产出端，追求产品质量的同时，再为用户、客户提供全方位贴切的服务。蓝亚信息的自研云端服务系统能力赋能企业与服务，让您的企业和产品，与世界同步、与时代同步！",
    bgUrl: require("../../assets/images/jjfa3.jpg"),
    usedList: [
      { name: "课堂直播", imgUrl: require("../../assets/images/jjfa3-1.jpg") },
      { name: "教学督导", imgUrl: require("../../assets/images/jjfa3-2.jpg") },
      {
        name: "在线教育平台",
        imgUrl: require("../../assets/images/jjfa3-3.jpg")
      }
    ],
    characteristic: require("../../assets/images/fatd3.png"),
    fadetail: {
      desc:
        "以建设/升级改造物联网智慧教室、远程教研室、互动专递直播间、云端教室等为基础，通过校园一体化平台及区域一体化平台，服务于区域内各个校园日常的教学管理体系及区域统一管理，实现区域教育一体化及资源均衡发展，真正推进智能双师课堂在广大中小学校的常态化应用。",
      imgUrl: require("../../assets/images/faxq3.jpg")
    },
    advantage: [
      { name: "云端服务支持", imgUrl: require("@/assets/images/5.png") },
      { name: "行业流程简化", imgUrl: require("@/assets/images/6.png") },
      { name: "行业数据引擎", imgUrl: require("@/assets/images/7.png") }
    ],
    partner: [
      "时度效",
      "迪高圈",
      "深圳市千代科技有限公司",
      "广东华城工程咨询有限公司惠州分公司",
      "广州长奋科技有限公司"
    ]
  },
  {
    id: 4,
    category: "应用平台",
    categoryUrl: require("@/assets/images/yypt.jpg"),
    title: "工业设备智能管理解决方案",
    desc:
      "工业生产现场设备的运行维护，是针对工业现场高价值、高能耗、富数据类的设备，采集设备运行过程数据，甚至根据业务分析需要，增加匹配功能的传感器，实现更丰富的过程数据采集。通过蓝亚物联网平台的设备组态工具、建模工具、数据分析工具等，快速实现设备监测和管理的可视化、数据分析和工艺、质量优化等。提供的一站式的工业物联解决方案，面向工业现场，具有学习门槛低，快速上线等优点",
    bgUrl: require("../../assets/images/jjfa4.jpg"),
    usedList: [
      { name: "课堂直播", imgUrl: require("../../assets/images/jjfa4-1.jpg") },
      { name: "教学督导", imgUrl: require("../../assets/images/jjfa4-2.jpg") },
      {
        name: "在线教育平台",
        imgUrl: require("../../assets/images/jjfa4-3.jpg")
      }
    ],
    characteristic: require("../../assets/images/fatd4.png"),
    fadetail: {
      desc:
        "以建设/升级改造物联网智慧教室、远程教研室、互动专递直播间、云端教室等为基础，通过校园一体化平台及区域一体化平台，服务于区域内各个校园日常的教学管理体系及区域统一管理，实现区域教育一体化及资源均衡发展，真正推进智能双师课堂在广大中小学校的常态化应用。",
      imgUrl: require("../../assets/images/faxq4.png")
    },
    advantage: [
      { name: "高精度采集芯片", imgUrl: require("@/assets/images/5.png") },
      { name: "海量数据存储能力", imgUrl: require("@/assets/images/6.png") },
      {
        name: "物联网行业数据分析引擎",
        imgUrl: require("@/assets/images/7.png")
      },
      { name: "高效预警技术", imgUrl: require("@/assets/images/8.png") },
      { name: "高拓展兼容支持", imgUrl: require("@/assets/images/8_1.png") }
    ],
    partner: [
      "深圳市千代科技有限公司",
      "野村综合研究所",
      "腾讯云",
      "阿里云",
      "广州市星翼电子科技有限公司",
      "北京涛思数据科技有限公司"
    ]
  }
];
